<template>
    <a-modal v-model="visible" :title="name" :width="800" :footer="null" centered destroyOnClose>
        <k-form-build :value="jsonData" />
    </a-modal>
</template>

<script>
import utils from '@/common/utils';
export default {
    data() {
        return {
            visible: false,
            name: '',
            jsonData: {}
        }
    },
    methods: {
        show(name, data) {
            if (utils.isEmpty(data)) {
                utils.error('没有可预览的表单数据');
                return;
            }
            this.name = name;
            this.jsonData = JSON.parse(data);
            this.visible = true;
        },
        _close() {
            this.visible = false;
        }
    }
}
</script>

<style></style>